export const domainNames = {
    "app.gearseo.com.br": "Gear SEO",
    "reviews.10thousandfeet.com": "10 Thousand Feet",
    "app.localtouch.io": "Local Touch",
    "local.findyouraudience.online": "Find Your Audience",
    "reviews.insiderperks.com": "Insider Perks",
    "reputationmanager.io": "Reputation Manager",
    "gmn.astradigital.com.br": "Astra Digital",
    "app.ormanagement.co": "OR Management",
    "cloud.mybusiness.tw": "Cloud My Business",
    "local.leadcrossing.com": "Lead Crossing",
    "reputation.itsevolve.com": "It's Evolve",
    "control.wela.com": "Wela",
    "reviews.skyeline.com": "Skyeline",
    "reviews.engageq.com": "EngageQ",
    "reviews.motivatedmarketing.com": "MotivatedMarketing",
    "app.getlocaladvantage.com": "Local Advantage",
    "reputation.thatsbiz.com": "That's Biz",
    "grow.digimarketer.ca": "LocalClarity",
    "app.hospitality-buddy.eu": "Hospitality Buddy",
    "orm.marinemarketingsolutions.net": "Marine Marketing",
    "localhost:3000":"LocalClarity",
    "dev.localclarity.com":"LocalClarity",
    "app.localclarity.com":"LocalClarity",
    'app.googlebusiness.ca':'Digi Marketer',
    'client.googavis.fr ':'LocalClarity',
    'reputation.easyfishmarketing.com': 'LocalClarity',
    'app.beoordeel.online':'Beoordeel',
    'app.bizzrep.com':'LocalClarity',
    'stage.localclarity.com': 'LocalClarity',
    'footfall.opositive.io':"Footfall",
    'reply.asterfive.com':"AsterFive",
    'app.onereputation.it':"One Reputation",
    'reviews.rankmeone.ai':'Rank Me One'

}